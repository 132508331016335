import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface TextCheckProps {
  text: string;
}

export default function TextCheck({ text }: TextCheckProps) {
  return (
    <p style={{ padding: '0', margin: '0', fontWeight: 'bolder', fontSize: '32px', color: '#fff' }}>
      <CheckBoxIcon color='primary' style={{ fontSize: '28px' }} /> {text}
    </p>
  );
}
