import styled from '@emotion/styled';
import { Paper, Box, Grid } from '@mui/material';
import Title from '../Title';
import Text from '../Text';
import AppButton from '../Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';
import TextCheck from '../TextCheck';

const { innerWidth: width } = window;

export default function Landing() {
  const Item = styled(Paper)(({ theme }: any) => ({
    backgroundColor: '#000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <header style={{ position: 'relative' }}>
        <img src={width > 800 ? 'assets/images/2.jpeg' : 'assets/images/1.jpeg'} style={{ width: '100vw', maxWidth: '100%' }} />
        {width <= 800 ? (
          <div style={{ position: 'absolute', bottom: '50px', left: '50%', transform: 'translate(-50%)' }}>
            {/* <AppButton text='GARANTA SUA VAGA' /> */}
            <AppButton text='VAGAS ESGOTADAS' disabled={true} />
          </div>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  {/* <AppButton text='GARANTA SUA VAGA' fontSize='30px' borderRadius='35px' /> */}
                  <AppButton text='VAGAS ESGOTADAS' fontSize='30px' borderRadius='35px' disabled={true} />
                </Item>
              </Grid>
            </Grid>
          </Box>
        )}
      </header>
      <div style={{ marginTop: '62px', marginBottom: '100px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={10} md={8}>
              <Item>
                <Title text={'SOBRE O EVENTO'} />
              </Item>
            </Grid>
            <Grid item xs={10} md={8}>
              <Item>
                <Text text='Se você frequentemente' fontSize='32px' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextCheck text='Se sente pra baixo' />

                  <TextCheck text='Sente que precisa melhorar alguma área da sua vida' />

                  <TextCheck text='Busca melhores resultados na vida profissional' />

                  <TextCheck text='Constantemente entra em relacionamentos tóxicos' />

                  <TextCheck text='Tem medo de ficar sozinha ou nunca encontrar alguém ' />

                  <TextCheck text='Está insatisfeita com sua aparência e quer buscar a sua melhor versão ' />
                </div>
                <Text text='O treinamento MUDE foi desenvolvido especialmente para VOCÊ!' />

                {/* <Text
                  text='Se você frequentemente se sente para baixo Sente que precisa melhor alguma área da sua
vida, que poderia ter resultados melhores na vida profissional, constantemente entra em relacionamentos tóxicos, tem medo de ficar sozinha e nunca encontrar alguém, está insatisfeita com sua aparência e Quer viver a sua melhor versão,
O treinamento MUDE é pra você!'
                /> */}
                <p style={{ fontSize: '24px', color: '#fff', textAlign: 'center', fontWeight: 'bolder' }}>
                  O treinamento vai acontecer presencialmente em Londrina dia
                  <span style={{ color: '#E83A9C' }}> 30 de setembro às 14:00 horas no Shopping Aurora.</span>
                </p>

                <Text text='Serão pouquíssimas vagas para que possamos interagir e tirar dúvidas de cada uma das mulheres lá presente.' />
                {width <= 800 ? (
                  // <AppButton text='GARANTA SUA VAGA' />
                  <AppButton text='VAGAS ESGOTADAS' disabled={true} />
                ) : (
                  // <AppButton text='GARANTA SUA VAGA' fontSize='30px' borderRadius='35px' />
                  <AppButton text='VAGAS ESGOTADAS' fontSize='30px' borderRadius='35px' disabled={true} />
                )}
              </Item>
            </Grid>

            <Grid item xs={10} md={6}>
              <Item>
                {width > 800 ? (
                  <img src={'assets/images/isa.png'} style={{ width: '100vw', maxWidth: '300px' }} />
                ) : (
                  <img src={'assets/images/isa.png'} style={{ width: '100vw', maxWidth: '90%' }} />
                )}

                <Text text='Isadora Cambraia é formada em Psicologia, mestre em Análise do comportamento e Life Coach.' />
                <Text text='Autora de 3 livros, criadora do podcast “Inspira, Não Pira”' />
                <a href='https://www.facebook.com/cardosoisadora' target='_blank' style={{ backgroundColor: 'transparent', border: 0 }}>
                  <FacebookIcon color='primary' style={{ fontSize: '48px', cursor: 'pointer' }} />
                </a>
                <a href='https://www.instagram.com/isadoracambraia' target='_blank' style={{ backgroundColor: 'transparent', border: 0 }}>
                  <InstagramIcon color='primary' style={{ fontSize: '48px', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }} />
                </a>

                <a href='https://www.youtube.com/@isadora.cambraia' target='_blank' style={{ backgroundColor: 'transparent', border: 0 }}>
                  <YoutubeIcon color='primary' style={{ fontSize: '48px', cursor: 'pointer' }} />
                </a>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
