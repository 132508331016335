import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Button.css';
interface AppButtonProps {
  text: string;
  fontSize?: string;
  borderRadius?: string;
  disabled?: boolean;
}

export default function AppButton({ text, fontSize = '15px', borderRadius = '20px', disabled = false }: AppButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      variant='contained'
      size='large'
      style={{ fontWeight: 'bolder', fontSize: fontSize, borderRadius: borderRadius }}
      onClick={() => !disabled && navigate('/comprar')}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
