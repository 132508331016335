import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Landing from './components/Landing/Landing';
import { Routes, Route } from 'react-router-dom';
import Comprar from './components/Comprar/Comprar';
import Sucesso from './components/Sucesso/Sucesso';
import SucessoQR from './components/Sucesso/SucessoQR';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E83A9C', // primary pink
    },
    secondary: {
      main: '#ff4081', // rose gold or any other complementing color
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path='*' element={<Landing />}>
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
        {/* <Route path='comprar' element={<Comprar />} />
        <Route path='sucesso' element={<Sucesso />} />
        <Route path='sucessoQR' element={<SucessoQR />} /> */}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
